<ui-dialog>
  <ng-container title>{{ 'analytics.cookies.dialog.manageCookies' | translate }}</ng-container>
  <ng-container description>
    <p class="mb-45">{{ 'analytics.cookies.dialog.description' | translate }}</p>
    <div class="d-flex justify-content-between align-items-center">
      <h3>{{ 'analytics.cookies.dialog.necessaryCookies' | translate }}</h3>

      <ui-toggle ui-suffix [(ngModel)]="necessaryCookies" [disabled]="true"></ui-toggle>
    </div>
    <p class="cookie-type-description">{{ 'analytics.cookies.dialog.necessaryCookiesDescription' | translate }}</p>

    <div class="d-flex justify-content-between align-items-center">
      <h3>{{ 'analytics.cookies.dialog.analyticalCookies' | translate }}</h3>

      <ui-toggle ui-suffix [(ngModel)]="analyticalCookies"></ui-toggle>
    </div>
    <p class="cookie-type-description">{{ 'analytics.cookies.dialog.analyticalCookiesDescription' | translate }}</p>
  </ng-container>
  <button ui-button type="primary" primary-action (click)="confirmButton()">
    {{ 'generic.saveSettings' | translate }}
  </button>
  <button ui-button type="secondary" (click)="cancelButton()" secondary-action>
    {{ 'generic.cancel' | translate }}
  </button>
</ui-dialog>
